@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Squada+One&display=swap');

* {
    margin: 0%;
    padding: 0%;
}

.maincontPart {
    /* border: 2px solid red; */
    width: 100%;

}

.headingPart {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.head {
    width: 65%;
}

.para>p {
    color: black;
}

.btnnPart {
    border: 2px solid #009999;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    /* background-color: #99ff99; */
    background-color: #009999;
    border-radius: 0.7rem;  
}
.btnnPart>button {
    align-items: center;
    background-color: transparent;
    width: 100%;
    font-size: small;
    line-height: 110%;
    font-weight: 600;
    color: #fff;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Elevation */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    border-radius: 0.7rem;
    padding: 0.3rem;  
}
/* .btnnPart>button:hover{
    background-color: #99ff99;
   
} */

.diffpart {
    height: 8rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.insidepart {
    display: flex;
    flex-direction: row;
    width: 88%;
    align-items: center;
    justify-content: space-between;
}

.box1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.box1>p {
    font-size: small;
    text-align: center;
    color: #fff;
    border-width: 1px solid blue;
    position: absolute;
}

.box1_one {
    /* border: 2px solid red; */
    width: 75%;
    position: absolute;
    top: 6rem;
    left: 1.3rem;

}

.box_text {
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    font-family: "Caveat", cursive;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    /* border: 2px solid; */
}

.box2 {
    display: flex;
    justify-content: center;
    align-items: center;

}

.box2>h6 {
    border: 2px solid grey;
    padding: 0.5rem;
    font-size: 0.7rem;
    align-items: center;
    border-radius: 100%;
    background-color: grey;
    color: #fff;
}

.Imagee {
    width: 100%;
    height: 50%;
}

.path_container {
    width: 100%;
    height: 80vh;
    margin-top: 6vh;
    position: relative;
}

.path_image {
    width: 100%;
    height: 100%;
}

.path_one {
    position: absolute;
    width: 25vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: -2%;
    left: 34vh;
}

.path_text {
    font-size: small;
    color: #5A5858;
    font-weight: 550;
    line-height: 1.2rem;
}

.path_one1 {
    position: absolute;
    width: 25vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: -5%;
    left: 69vh;
}

.path_one2 {
    position: absolute;
    width: 25vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 3%;
    left: 101vh;
}

.path_one3 {
    position: absolute;
    width: 25vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 28%;
    left: 110vh;
}

.path_one4 {
    position: absolute;
    width: 25vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 25%;
    left: 70vh;
}

.path_one5 {
    position: absolute;
    width: 25vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 28%;
    left: 40vh;
}

.path_one6 {
    position: absolute;
    width: 20vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 60%;
    left: 19vh;
}

.path_one7 {
    position: absolute;
    width: 28vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 48%;
    left: 66vh;
}

.path_one8 {
    position: absolute;
    width: 18vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 80%;
    left: 33vh;
}

.path_one9 {
    position: absolute;
    width: 22vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 83%;
    left: 66vh;
}

.path_one1_1 {
    position: absolute;
    width: 24vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 1%;
    left: 162vh;
}

.path_text1 {
    font-size: small;
    color: #1BBDB5;
    font-weight: 550;
    line-height: 1.2rem;
}

.path_one1_2 {
    position: absolute;
    width: 28vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 27%;
    left: 165vh;
}

.path_one1_3 {
    position: absolute;
    width: 12vh;
    padding: 1vh;
    /* background: red; */
    /* z-index: 999; */
    top: 57%;
    left: 170vh;
}