*{
    padding: 0%;
    margin: 0%;
}
.contentt{
    display: flex;
    flex-direction: column;
    height: 18vh;
}
.part{
    /* border: 2px solid red; */
    width: 100%;
    height: 3.2rem;
    align-items: center;
    margin-bottom: 0.5rem;
}
.logo{
    color: rgb(66, 66, 66);
    font-size: 1.55rem;
    align-items: center;
}
.public_Text{
    color: rgb(35, 35, 35);
    font-size: small; 
}
.type_text{
    font-size: small; 
}
.texttt{
    display: flex;
    flex-direction: column;
    line-height: 100%;
}
.bold_textt{
    font-family: "Roboto", sans-serif;
    color: var(--clr-body-heading);
    margin-top: 0px;
    line-height: 1;
    font-weight: 700;
}
.Light_textt{
    font-size: 16px;
    display: inline-block;
    color: rgba(30, 30, 30, 0.7);
}










/* .bottomPart{
    border: 1px solid black;
    width: 99%;
    height: 2rem;
    border-radius: 2rem;
    display: flex;
   justify-content: center;
   align-items: center;
} */
/* .inside_content{
    width: 92%;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
} */
/* .COURSES_TEXT{
    font-weight: 700;
    color: black;
    font-size: 0.72rem;
} */
/* .arrow_icon{
    font-weight: 700;
    color: black;
    font-size: 1.2rem;
}
.course-top-title{
    display: flex;
    flex-direction: column;
    line-height: 90%;
}
.auburn_text{
    font-size: 0.8rem;
    color: rgb(83, 82, 82);
}
#uni_name{
    color: #0487f3 ;
}
.starIcon{
    font-size: 1.2rem;
    color: orange;
} */


/*   {/* <div className="contentt">
      <div className="part">
        <div className="logoo">
        <FaBuilding className="logo"/>
        </div>
        <div className="texts">
            <div className="texttt">
            <span className="bold_text">Public</span>
            <span className="Light_text">type</span>
            </div>
        </div>
      </div>
      <div className="part">
      <div className="logoo">
        <FaRegFlag className="logo"/>
        </div>
        <div className="texts">
        <div className="texttt">
            <span className="bold_text">1899</span>
            <span className="Light_text">Established Since</span>
            </div>
            <div>
              <span className="extra_text">Apply with</span>
            </div>
          </div>
      </div>
      <div className="part">
      <div className="logoo">
        <IoRibbonOutline className="logo"/>
        </div>
        <div className="texts">
          <div className="texttt">
            <span className="bold_text">1</span>
            <span className="Light_text">QS Ranking</span>
            </div>
            <div>
              <span className="extra_text">Fast-track</span>
            </div>
          </div>
      </div>
    </div>
    <div className="course-content-bottom">
      <div className="bottomPart">
        <div className="inside_content">
          <span className="COURSES_TEXT">RECOMMENDED COURSES</span>
          <MdOutlineKeyboardArrowRight className="arrow_icon"/>
        </div>
      </div>
    </div> */ 

    /*   <div className="course-top-title">
        <h6 id="uni_name">Auburn University</h6>
        <span className="auburn_text">Auburn</span>
      </div> */