.process-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  .process-content {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  
  .process {
    width: 45%;
  }
  
  .process h3 {
    background-color: #000; /* or a color to match the image design */
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
  }
  
  .step {
    margin: 15px 0;
    font-size: 1rem;
    color: #555;
  }
  
  .arrow {
    position: absolute;
  }
  
  .traditional-arrow {
    /* Use SVG or CSS to draw the curved path similar to the image */
    top: 20px;
    left: 10%;
    width: 80px;
    height: 200px;
    /* background-image: url('path-to-traditional-arrow.svg');  */
  }
  
  .f1visa-arrow {
    /* Use SVG or CSS to draw the curved path similar to the image */
    top: 20px;
    right: 10%;
    width: 80px;
    height: 200px;
    /* background-image: url('path-to-f1visa-arrow.svg');  */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .process-content {
      flex-direction: column;
    }
  
    .process {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .arrow {
      display: none; /* Hide or adapt arrows for smaller screens */
    }
  }
  