@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @font-face {
    font-family: Roboto;
    src: url('../../../public/fonts/Roboto-MediumItalic.ttf');
}

@font-face {
    font-family: Robotooo;
    src: url('../../../public/fonts/Poppins-Medium.ttf');
} */

/* .header_questions {
    top: 0px;
    position: fixed;
    height: 4.5rem;
    width: 100%;
    background-color: #1F6689;
    display: flex;
    justify-content: flex-end;
} */

.content_to_last {
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .container_box {
    height: 4rem;
    width: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #ffff;
    border-radius: 5px;
    cursor: pointer;
} */

.content_headerrr {
    color: #ffff;
    font-size: 0.8rem;
    font-weight: 500;
}

.for_iconssss {
    color: #ffff !important;
    font-weight: bold;
}

#link_next {
    color: #ffff;
}

.inside_containerrr {
    display: flex;
    gap: 15px;
}




/*  */
.to_flex_time_last_content {
    margin-top: 4.5rem;
    height: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.side_contwnt_time {
    width: 20%;
    height: 100%;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;

}

.for_give_border {
    padding: 0.2rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-weight: 600;
    border-right: 1px solid #000;
}

.watchhhhh {
    color: #000;
}

.to_fontttt {
    color: #000;
}

.inside_content {
    display: flex;
    align-items: center;
    gap: 5px;
}

/*  */



/* side_by_side */
.two_Containerr_side {
    margin-top: 1px;
    width: 100%;
    height: 80vh;
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
    padding: 0.6rem;
}

.two_Containerr_side1 {
    margin-top: 10px;
    width: 100%;
    height: 80vh;
    border-top: 1px solid black;

}

.side_left_con {
    width: 50%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
}

.side_left_con1 {
    width: 50%;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    border-right: 1px solid #000;
}

.container_paragr {
    display: flex;
    gap: 0.2rem;
    font-size: 1rem;
    color: #000;
    font-family: Roboto;
}

.indide_side_barrr {
    padding: 2rem;
}

.top_sdie_content {
    color: #000;
    font-size: 1.1rem;
    font-family: Roboto;

}

.for_select_question {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.fofr_queistoon {
    color: #000;
    font-size: 1rem;
    font-family: Roboto;
}

.for_all_Questions {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.side_right_con {
    width: 50%;
    height: 100%;

}

.down_arrowwwwww {
    display: flex;
    padding-top: 2rem;
    align-items: center;
    gap: 10px;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
}

/* testing instruction starting */
.to_contenttttttt {
    top: 0px;
    position: fixed;
    height: 4.5rem;
    width: 100%;
    background-color: #1F6689;
    display: flex;
    justify-content: flex-end;
}

.side_fleeeeexxx {
    width: 15%;
    height: 100%;
    display: flex;
    gap: 10px;
}


.container_boxxxxx {
    height: 4rem;
    width: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid #ffff;
    border-radius: 5px;
}

.content_headerrrrrrr {
    color: #ffff;
    font-size: 0.6rem;
    font-weight: 500;
}

.for_iconssss_icon {
    color: #ffff !important;
    font-weight: bold;
    font-size: 0.9rem;
}


.general_instruction {
    margin-top: 4.5rem;
}

.general_instruction {
    height: 100%;
    width: 100%;
    padding: 2rem;
}

.main_cointainerrrrrr {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.continaerrr_Center {
    width: 80%;
    height: 100%;

}

.contentt_togive_under {
    width: 80%;
    height: 3rem;
    border-bottom: 2px solid #000;
}

.test_test_gen {
    color: #000;
    font-weight: bold;
    font-size: 1.5rem;
}

.sub_contentttttttt {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.3rem
}


.text_contttt {
    color: #000;
    font-family: Robotooo;
}

.text_contttt22 {
    color: #000;
    display: flex;
    gap: 2px;
    font-family: Robotooo;
}

.bold_contenttt {
    color: #000;
    font-weight: 600;
}

/* ----------end---- */




/* profile */

.profile_imageeee {
    margin-top: 4rem;
}

.inside_profileeee {
    height: 30rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;

}

.prodile_cotttt {
    width: 15rem;
    height: 15rem;
    background-color: yellow;
}

.img_insthat {
    height: 100%;
    width: 100%;
}

.top_img {
    color: #000;
    font-weight: 500;
    font-size: 0.9rem;
    font-family: Robotooo;
}

.on_click_on {
    color: #000;
    font-size: 0.9rem;
    font-family: Robotooo;
}

.cotinous {
    color: #000;
    font-weight: bold;
    font-family: Robotooo;
}


/* end */


/* here it down profile */


.top_conte_downnn {
    height: 3.3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.containerrrrrrr_Wri {
    display: flex;
    padding: 0.4rem;
    align-items: center;
    gap: 5px;
}

.wriiting_contentttt {
    color: #000;
    display: flex;
    font-weight: 500;
    align-items: center;
    height: 1rem;
    gap: 2px;
    border-right: 2px solid #000;
}

.questoonnnnn {
    color: #000;
    font-weight: 500;
}

/* end */


/* listiong */

.total_container {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.to_flex_endddddd {
    height: 1.9rem;
    width: 15%;
    margin-right: 0.5rem;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    background-color: #1F6689;
    ;
}

.slider_new {
    -webkit-appearance: none;
    appearance: none;
    height: 12px;
    width: 80%;
    background: #fff;
    border-radius: 6px;
    outline: none;
    opacity: 0.9;
    transition: opacity .2s;
}

/* .slider_new:hover {
    opacity: 1;
} */

/* Styling for the slider thumb/handle */
.slider_new::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #ffff;
    border-radius: 5px;
    border: 2px solid #ffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.slider_new::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 10px;
    border: 2px solid #468096;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

/*  */


/* videoooooo */

.inside_videosss {
    margin-top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.with_totallll {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #468096;
    font-weight: bold;
}

.inside_videosss_sub {
    height: 50%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* video endddd */

.top_headerrr_con {
    width: 100%;
    height: 4rem;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}


.side_contenttt {
    color: #000;
    font-weight: 500;
}

.sub_colorrr {
    color: #ffff;
    height: 2.5rem;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: #0E9478;
}

.for_multipleeee_question {
    width: 100%;
    padding: 2rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
}

.contentt_about_grpag {
    color: #000;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    font-family: Roboto;

}

.containerrr_grpahhhh_Centerr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerrr_grpahhhh_Centerr img{
    max-height: 30% !important;
    height: 30% !important;
}

.above_quuuu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.down_questionnnn {
    width: 85%;
    font-size: 1.2rem;
    color: #000;
    font-family: Roboto;
}

.input_sub_container {
    height: 2rem;
    width: 2rem;
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.for_answerrrr_content {
    display: flex;
    flex-direction: row;
}

.for_answerrrr_content {
    color: #000;
    font-family: Robotooo;
    font-size: 1.1rem;
}

.input_containerrrr {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}

.above_container_innnn {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.all_input_questionnnnn {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

/* single_question */

.inn_Qqqqq {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.single_questoonnnnn {
    width: 90%;
    padding: 2.5rem;
}

.color_for_questionnn {
    color: #000;
    font-size: 1.3rem;
    font-weight: 500;
    font-family: Roboto;
}

.check_box_questi {
    display: flex;
    align-items: center;
    gap: 10px;
}


.totallll {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.to_make_that_sodeeee {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;

}

.inpuutt_Che {
    color: #000;
}

.color_optionn {
    color: #000;
    font-family: Roboto;
    font-size: 1.3rem;
}

.check_boxxxxx {
    border: 1px solid #000;
    border-radius: 4px;
}

.total_answerrrr {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.clickeeddD_answeee_and_explainatiopn {
    height: "auto";
    width: 90%;
    border: 2px solid #cccccc;
}

.check_contentttt {
    display: flex;
    color: #000;
    align-items: center;
}

.total_clicked_answerrr {
    display: flex;
    align-items: center;
    padding-left: 1.4rem;
    gap: 0.7rem;
    height: 2.5rem;
    border-bottom: 2px solid #cccccc;
}

.expaination_answersss {
    padding-left: 1.4rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.for_answer_expaa {
    color: #000;
    font-weight: bold;
    font-family: Roboto;
    font-size: 1.2rem;
}

.to_give_top_spaceee {
    margin-top: 3rem;
}

.correct_answerrrr {
    color: #000;
    font-weight: 500;
    font-size: 1.1rem;
}

.inside_expainationnnn {
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-family: Roboto;
}

.down_contenttttt_Flexeee {
    display: flex;
    flex-direction: row;
}

.side_conatinerrrrrr {
    width: 80%;
    height: 100vh;
    overflow: scroll;
    overflow-x: hidden;
}

.side_conatinerrr_siddeee {
    width: 25%;
    height: auto;
}

/* side_box_ */
.total_conatinerrrsss {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.conatinerrr_sub_boxx {
    width: 90%;
    max-height: 85vh;
    padding: 0.1rem;
    display: flex;
    overflow: scroll;
    overflow-x: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    border: 1px solid black;
    border-radius: 0.5rem;
    /* align-items: center; */
    background-color: #ffff;
}

.conatinerrr_sub_boxx::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
}

.conatinerrr_sub_boxx::-webkit-scrollbar-thumb {
    background-color: #1F6689;
    ;
    /* Scrollbar thumb color */
    border-radius: 4px;
    /* Rounded corners */
}

.conatinerrr_sub_boxx::-webkit-scrollbar-thumb:hover {
    background-color: #1F6689;
    /* Thumb color on hover */
}

/* For Firefox */
.conatinerrr_sub_boxx {
    scrollbar-width: thin;
    /* Thinner scrollbar */
    scrollbar-color: #1F6689 #f0f0f0;
    /* Thumb and track color */
}


.sub_insiddddeee {
    height: 3.3rem;
    display: flex;
    background-color: #90A4AE;
    align-items: center;
    justify-content: center;
    width: 3.3rem;
    border-radius: 0.2rem;
}

.sub_insiddddeee1 {
    height: 3.3rem;
    display: flex;
    background-color: #FFEB3B;
    align-items: center;
    justify-content: center;
    width: 3.3rem;
    border-radius: 0.2rem;
}

.selected_container {
    color: #000;
    font-size: 1.1rem;
    font-weight: 600;
}

.selected_container1 {
    color: #000;
    font-size: 1.1rem;
    font-weight: 600;
}

@media only screen and (max-width:380px) {



    .down_contenttttt_Flexeee {
        display: flex;
        flex-direction: column-reverse;

    }

    .side_conatinerrrrrr {
        width: 100%;
        height: 100vh;
        overflow: scroll;
        overflow-x: hidden;
    }

    .side_conatinerrr_siddeee {
        width: 100%;
        height: auto;
    }

    .total_conatinerrrsss {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
    }


    .conatinerrr_sub_boxx {
        width: 100%;
        height: auto;
        padding: 0.1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        border: 1px solid black;
        border-radius: 0.5rem;
        align-items: center;
        background-color: #ffff;
    }

    .sub_insiddddeee {
        height: 3rem;
        display: flex;
        background-color: #90A4AE;
        align-items: center;
        justify-content: center;
        width: 3rem;
        border-radius: 0.2rem;
    }

    .sub_insiddddeee1 {
        height: 3rem;
        display: flex;
        background-color: #FFEB3B;
        align-items: center;
        justify-content: center;
        width: 3rem;
        border-radius: 0.2rem;
    }

    .selected_container {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .selected_container1 {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
    }


    .inside_expainationnnn {
        color: #000;
        font-size: 0.8rem;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-family: Roboto;
    }

    .correct_answerrrr {
        color: #000;
        font-weight: 500;
        font-size: 0.8rem;
    }

    .for_answer_expaa {
        color: #000;
        font-weight: bold;
        font-family: Roboto;
        font-size: 0.9rem;
    }



    .inn_Qqqqq {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .single_questoonnnnn {
        width: 95%;
        padding: 0.6rem;
    }

    .color_for_questionnn {
        color: #000;
        font-size: 1rem;
        font-weight: 500;
        font-family: Roboto;
    }

    .check_box_questi {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .to_make_that_sodeeee {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 0.9rem;

    }

    .color_optionn {
        color: #000;
        font-family: Roboto;
        font-size: 1rem;
    }


    .for_multipleeee_question {
        width: 100%;
        padding: 0.9rem;
        display: flex;
        gap: 0.6rem;
        flex-direction: column;
        align-items: center;
    }

    .contentt_about_grpag {
        color: #000;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.9rem;
        font-family: Roboto;

    }

    .down_questionnnn {
        width: 85%;
        font-size: 0.9rem;
        color: #000;
        font-family: Roboto;
    }


    .top_headerrr_con {
        width: 100%;
        height: 4rem;
        background-color: #f2f2f2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem;
    }

    .input_sub_container {
        height: 2rem;
        width: 2rem;
        border: 1px solid #cccccc;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .for_answerrrr_content {
        display: flex;
        flex-direction: row;
    }

    .for_answerrrr_content {
        color: #000;
        font-family: Robotooo;
        font-size: 0.8rem;
    }


    .all_input_questionnnnn {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.8rem;
    }


    .side_contenttt {
        color: #000;
        font-weight: 500;
        font-size: 0.7rem;
    }

    .sub_colorrr {
        color: #ffff;
        height: 2rem;
        padding: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        background-color: #0E9478;
    }








    .inside_videosss {
        margin-top: 0.5rem;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .inside_videosss_sub {
        height: 100%;
        width: 100%;
    }

    .general_instruction {
        margin-top: 4.5rem;
    }

    .general_instruction {
        height: 100%;
        width: 100%;
        padding: 1rem;
    }

    .contentt_togive_under {
        width: 95%;
        height: 2.8rem;
        border-bottom: 2px solid #000;
    }

    .test_test_gen {
        color: #000;
        font-weight: bold;
        font-size: 1rem;
    }

    .main_cointainerrrrrr {
        margin-top: 0.5rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .continaerrr_Center {
        width: 90%;
        height: 100%;

    }

    .sub_contentttttttt {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem
    }

    .content_to_last {
        height: 100%;
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .side_contwnt_time {
        width: 50%;
        height: 100%;
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.5rem;

    }

    .to_fontttt {
        font-size: 0.7rem;
        color: #000;
    }

    .inside_containerrr {
        display: flex;
        gap: 8px;
    }

    .container_box {
        height: 2.8rem;
        width: 2rem;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid #ffff;
        border-radius: 5px;
    }

    .content_headerrr {
        color: #ffff;
        font-size: 0.5rem;
        font-weight: 500;
    }

    .for_iconssss {
        color: #ffff !important;
        font-weight: bold;
        font-size: 0.6rem;
    }

    .two_Containerr_side {
        margin-top: 10px;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        padding: 0.1rem;
    }

    .side_left_con {
        width: 100%;
        height: 50rem;
        overflow: scroll;
    }

    .side_left_con1 {
        width: 100%;
        height: 50rem;
        overflow: scroll;
    }

    .container_paragr {
        display: flex;
        gap: 0.1rem;
        font-size: 0.8rem;
        color: #000;
    }

    .side_right_con {
        width: 100%;
        height: 40%;

    }

    .for_all_Questions {
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .indide_side_barrr {
        padding: 0.5rem;
    }

    .top_sdie_content {
        color: #000;
        font-size: 0.9rem;
        /* font-family: 'Roboto', sans-serif */
    }

    .fofr_queistoon {
        color: #000;
        font-size: 0.9rem;
    }

}

@media only screen and (min-width :380px) and (max-width:500px) {


    .down_contenttttt_Flexeee {
        display: flex;
        flex-direction: column-reverse;

    }

    .side_conatinerrrrrr {
        width: 100%;
        height: 100vh;
        overflow: scroll;
        overflow-x: hidden;
    }

    .side_conatinerrr_siddeee {
        width: 100%;
        height: auto;
    }

    .total_conatinerrrsss {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
    }

    .conatinerrr_sub_boxx {
        width: 100%;
        height: auto;
        padding: 0.1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        border: 1px solid black;
        border-radius: 0.5rem;
        align-items: center;
        background-color: #ffff;
    }

    .sub_insiddddeee {
        height: 3rem;
        display: flex;
        background-color: #90A4AE;
        align-items: center;
        justify-content: center;
        width: 3rem;
        border-radius: 0.2rem;
    }

    .sub_insiddddeee1 {
        height: 3rem;
        display: flex;
        background-color: #FFEB3B;
        align-items: center;
        justify-content: center;
        width: 3rem;
        border-radius: 0.2rem;
    }

    .selected_container {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .selected_container1 {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
    }




    .check_boxxxxx {
        border: 1px solid #000;
        border-radius: 4px;
    }

    .color_optionn {
        color: #000;
        font-family: Roboto;
        font-size: 1.1rem;
    }

    .color_for_questionnn {
        color: #000;
        font-size: 1rem;
        font-weight: 500;
        font-family: Roboto;
    }

    .single_questoonnnnn {
        width: 90%;
        padding: 0.3rem;
    }





    .for_multipleeee_question {
        width: 100%;
        padding: 1.5rem;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .contentt_about_grpag {
        color: #000;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.9rem;
        font-family: Roboto;

    }

    .down_questionnnn {
        width: 85%;
        font-size: 1rem;
        color: #000;
        font-family: Roboto;
    }

    .for_answerrrr_content {
        display: flex;
        flex-direction: row;
    }

    .for_answerrrr_content {
        color: #000;
        font-family: Robotooo;
        font-size: 0.9rem;
    }

    .top_headerrr_con {
        width: 100%;
        height: 4rem;
        background-color: #f2f2f2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.7rem;
    }


    .side_contenttt {
        color: #000;
        font-weight: 500;
    }

    .sub_colorrr {
        color: #ffff;
        height: 2.2rem;
        padding: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        background-color: #0E9478;
    }

    .inside_videosss {
        margin-top: 0.5rem;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .inside_videosss_sub {
        height: 100%;
        width: 100%;
    }

    .main_cointainerrrrrr {
        margin-top: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .continaerrr_Center {
        width: 90%;
        height: 100%;

    }

    .general_instruction {
        margin-top: 4.5rem;
    }

    .general_instruction {
        height: 100%;
        width: 100%;
        padding: 1.5rem;
    }

    .contentt_togive_under {
        width: 90%;
        height: 3rem;
        border-bottom: 2px solid #000;
    }

    .test_test_gen {
        color: #000;
        font-weight: bold;
        font-size: 1.3rem;
    }

    .sub_contentttttttt {
        padding-top: 1.8rem;
        display: flex;
        flex-direction: column;
        gap: 1rem
    }

    .content_to_last {
        height: 100%;
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .side_contwnt_time {
        width: 50%;
        height: 100%;
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.5rem;

    }

    .to_fontttt {
        font-size: 0.7rem;
        color: #000;
    }

    .container_box {
        height: 2.8rem;
        width: 2rem;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid #ffff;
        border-radius: 5px;
    }

    .content_headerrr {
        color: #ffff;
        font-size: 0.5rem;
        font-weight: 500;
    }

    .for_iconssss {
        color: #ffff !important;
        font-weight: bold;
        font-size: 0.6rem;
    }

    .inside_containerrr {
        display: flex;
        gap: 10px;
    }

    .for_iconssss {
        color: #ffff !important;
        font-weight: bold;
        font-size: 0.6rem;
    }

    .two_Containerr_side {
        margin-top: 10px;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        padding: 0.1rem;
    }

    .side_left_con {
        width: 100%;
        height: 50rem;
        overflow: scroll;

    }

    .side_left_con1 {
        width: 100%;
        height: 50rem;
        overflow: scroll;

    }

    .container_paragr {
        display: flex;
        gap: 0.1rem;
        font-size: 0.8rem;
        color: #000;
        font-family: "PoppinsBold", sans-serif;
    }

    .side_right_con {
        width: 100%;
        height: 40%;


    }

    .for_all_Questions {
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .indide_side_barrr {
        padding: 0.5rem;
    }

    .top_sdie_content {
        color: #000;
        font-size: 0.9rem;

    }

    .fofr_queistoon {
        color: #000;
        font-size: 0.9rem;
    }





}


@media only screen and (min-width :500px) and (max-width:768px) {

    .down_contenttttt_Flexeee {
        display: flex;
        flex-direction: column-reverse;

    }

    .side_conatinerrrrrr {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        overflow-x: hidden;
    }

    .side_conatinerrr_siddeee {
        width: 100%;
        height: auto;
    }


    .total_conatinerrrsss {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
    }

    .conatinerrr_sub_boxx {
        width: 50%;
        height: auto;
        padding: 0.1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
        border: 1px solid black;
        border-radius: 0.5rem;
        align-items: center;
        background-color: #ffff;
    }

    .sub_insiddddeee {
        height: 3rem;
        display: flex;
        background-color: #90A4AE;
        align-items: center;
        justify-content: center;
        width: 3rem;
        border-radius: 0.2rem;
    }

    .sub_insiddddeee1 {
        height: 3rem;
        display: flex;
        background-color: #FFEB3B;
        align-items: center;
        justify-content: center;
        width: 3rem;
        border-radius: 0.2rem;
    }

    .selected_container {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .selected_container1 {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
    }

    .general_instruction {
        margin-top: 4.5rem;
    }

    .general_instruction {
        height: 100%;
        width: 100%;
        padding: 1.8rem;
    }

    .contentt_togive_under {
        width: 80%;
        height: 3rem;
        border-bottom: 2px solid #000;
    }

    .test_test_gen {
        color: #000;
        font-weight: bold;
        font-size: 1.5rem;
    }

    .sub_contentttttttt {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1.6rem
    }

    .side_contwnt_time {
        width: 30%;
        height: 100%;
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0.5rem;
    }

    .two_Containerr_side {
        margin-top: 10px;
        width: 100%;
        height: 90vh;
        display: flex;
        flex-direction: row;
        padding: 0.6rem;
    }

    .indide_side_barrr {
        padding: 1.5rem;
    }

    .to_fontttt {
        font-size: 0.7rem;
        color: #000;
    }

    .fofr_queistoon {
        color: #000;
        font-size: 0.9rem;
    }

    .content_to_last {
        height: 100%;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container_box {
        height: 3.8rem;
        width: 2.9rem;
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid #ffff;
        border-radius: 5px;
    }

    .inside_containerrr {
        display: flex;
        gap: 10px;
    }

}