* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}


/* Nishitha */
.totalcontent {
    overflow: none;
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{
background-color: #002676 !important;
}

.custom-cell {
    color: #ffff;
    text-align: center;
    background-color: #002676;
}

.containerrrdatagrod::-webkit-scrollbar{
    display:none;
}

.background_image {
    width: 100%;
    height: 23rem;
}

.background_image img {
    width: 100%;
    height: 100%;
}

.bluecontainer {
    top: 16rem;
    left: 5rem;
    position: absolute;
    width:auto;
    height: 5rem;
    background-color: #431170;
    border: 1px solid #431170;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.2rem;
}

.bluecontainer h1 {
    color: #ffff;
    font-weight: 900;
    text-align: center;
    font-size: 2.3rem;
}

.paracontainer {
    margin: 5rem;
    margin-right: 9.5rem;
}


.content_para {
    color: #000;
    font-size: 2.2rem;
    line-height: 1.3125;
}

.desktop_down_content {

    width: 100%;
    /* height:50vh; */
    height: 63rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
}

.to_give_margin {
    width: 100%;
    height: 100%;
    margin: 5rem;
    display: flex;
    gap: 1rem;
    /* padding-top: 1rem; */
    /* background-color: blue; */
}

.leftcontainer {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rightcontainer {
    width: 50%;
    height: 100%;
}

.custom-column-cell {
    color: #000;
}

.imagecsideontainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1.3rem;
}

.imageforsort {
    width: 22rem;
    height: 8rem;
}

.sideparaside {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
    font-family: sans-serif;
}

.sideparaside h4 {
    font-size: 1.5rem;
    color: #002676;
    text-decoration: underline;
}

.sideparaside h4:hover {
    transition: all 2s;
    text-decoration: none;
}

.sidelargeimage {
    height: 100%;
    width: 100%;
}

.imagetower {
    border-radius: 0.5rem;
}

.containeruc {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentwhatt {
    padding-top: 0.5rem;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentwhatt h5 {
    transition: all 2s;
    text-decoration: none;
}

.contentwhatt h5:hover {
    text-decoration: underline;
}


/* hex */
.hexagon {
    position: relative;
    width: 109px;
    height: 55px;
    background-color: #002676;
    margin: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    border-left: solid 5px #002676;
    border-right: solid 5px #002676;
}

.hexagon:before {
    content: "";
    position: absolute;
    top: -27px;
    left: 0;
    width: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 27.5px solid #002676;
}

.hexagon:after {
    content: "";
    position: absolute;
    bottom: -26px;
    left: 0;
    width: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 27.5px solid #002676;
}

.hexagon h3 {
    color: #ffff;
    text-align: center;

}

.circle {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #002676;
    border-radius: 50%;
}

.circle h3 {
    color: #ffff;
    font-size: 1.5rem;
}

.subconttainertoflex {
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.inside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inside p {
    width: 14rem;
    text-align: center;
}

.sub_data {
    margin-top: 1rem;
}

.rightcontainer p {
    padding-top: 0.5rem;
    color: #000;
}

.leftcontainer p {
    color: #000;
}

.mobile_versionsss {
    width: 100%;
    display: none;
    overflow: hidden;
    margin-top: 1rem;
}

.nine {
    font-size: 2rem !important;
}

.css-1rtad1::-webkit-scrollbar {
    display: none;
}

.custom-header1 {
    color: #ffff;
    background-color: #002676;
    text-align: center;
}

.custom-header2 {
    color: #ffff;
    background-color: #002676;
    text-align: center;
}

.MuiDataGrid-filler {
    background-color: #002676;
    display: none !important;
}

.custom-header {
    color: #ffff;
    background-color: #002676;
    text-align: center;
}

.css-jtj9el-MuiDataGrid-root .MuiDataGrid-container--top [role=row] {
    background-color: #002676 !important;
}

/* ---
--------------------=respinsive code =-------------- */

@media only screen and (max-width: 480px) {
    .desktop_down_content {
        display: none;
    }

    .innersub {
        width: 50%;
    }

    .hexagon1 {
        position: relative;
        width: 109px;
        height: 55px;
        background-color: #002676;
        margin: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: inline-block;
        border-left: solid 5px #002676;
        border-right: solid 5px #002676;
    }

    .hexagon1:before {
        content: "";
        position: absolute;
        top: -27px;
        left: 0;
        width: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 27.5px solid #002676;
    }

    .hexagon1:after {
        content: "";
        position: absolute;
        bottom: -26px;
        left: 0;
        width: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-top: 27.5px solid #002676;
    }

    .hexagon1 h3 {
        color: #ffff;
        text-align: center;

    }

    .circle1 {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #002676;
        border-radius: 50%;
    }

    .circle1 h3 {
        color: #ffff;
        font-size: 1.5rem;
    }

    .mobilehappens {
        /* width: 90%; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
    }

    .subcontainerrssmobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .innersub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }


    .subcontainerrssmobile {
        background-color: white;
    }

    .totaldowncontemt {
        display: flex;
        /* background-color: red; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 28rem;
    }

    .subcontainerrssmobile p {
        width: 90%;
        color: #000;
        text-align: center;
    }

    .mobilehappens h5 {
        text-align: center;

        color: #002676;
        text-decoration: none;
    }

    .mobilehappens h5:hover {
        text-decoration: underline;
    }

    .mobile_versionsss {
        width: 100%;
        overflow: hidden;
        display: block;
    }

    .conaatinersubmon {
        width: 100%;
        margin-top: 1rem;
        height: 22rem;
        /* background-color: blue; */
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .imagofmobile {
        width: 100%;
        height: 12rem;
    }

    .imagofmobile img {
        width: 100%;
        height: 100%;
    }

    .conatiner_mobile {
        width: 96%;
        height: 100%;
        /* background-color: red; */
    }

    .conentsby h4 {
        color: #002676;
        padding-top: 0.4rem;
        text-decoration: underline;
    }

    .conentsby h4:hover {
        text-decoration: none;
    }




    .background_image {
        width: 100%;
        height: 18rem;
    }

    .background_image img {
        width: 100%;
        height: 100%;
    }

    .bluecontainer {
        top: 12rem;
        left: 1rem;
        position: absolute;
        width: auto;
        height: 5.5rem;
        background-color: #431170;
        border: 1px solid #431170;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.2rem;
    }

    .bluecontainer h1 {
        color: #ffff;
        font-weight: 900;
        font-size: 1.8rem;
    }

    .paracontainer {
        margin: 1rem;
        padding-top: 1.5rem;
        margin-right: 0rem;
    }


    .content_para {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 1.5rem;

        line-height: 1.3;
        padding-bottom: 0.5rem
    }

}


@media only screen and (min-width:480px) and (max-width:800px) {

    .desktop_down_content {
        display: none;
    }

    .innersub {
        width: 50%;
    }

    .mobile_versionsss {
        width: 100%;
        display: block;
        margin-top: 1rem;
    }

    .hexagon1 {
        position: relative;
        width: 109px;
        height: 55px;
        background-color: #002676;
        margin: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: inline-block;
        border-left: solid 5px #002676;
        border-right: solid 5px #002676;
    }

    .hexagon1:before {
        content: "";
        position: absolute;
        top: -27px;
        left: 0;
        width: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 27.5px solid #002676;
    }

    .hexagon1:after {
        content: "";
        position: absolute;
        bottom: -26px;
        left: 0;
        width: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-top: 27.5px solid #002676;
    }

    .hexagon1 h3 {
        color: #ffff;
        text-align: center;

    }

    .circle1 {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #002676;
        border-radius: 50%;
    }

    .circle1 h3 {
        color: #ffff;
        font-size: 1.5rem;
    }

    .mobilehappens {
        /* width: 90%; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
    }

    .subcontainerrssmobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .innersub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }


    .subcontainerrssmobile {
        background-color: white;
    }

    .totaldowncontemt {
        display: flex;
        /* background-color: red; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 28rem;
    }

    .subcontainerrssmobile p {
        width: 90%;
        color: #000;
        text-align: center;
    }

    .mobilehappens h5 {
        text-align: center;

        color: #002676;
        text-decoration: none;
    }

    .mobilehappens h5:hover {
        text-decoration: underline;
    }

    .mobile_versionsss {
        width: 100%;
        overflow: hidden;
        display: block;
    }

    .conaatinersubmon {
        width: 100%;
        margin-top: 1rem;
        height: 22rem;
        /* background-color: blue; */
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .imagofmobile {
        width: 100%;
        height: 14rem;
    }

    .imagofmobile img {
        width: 100%;
        height: 100%;
    }

    .conatiner_mobile {
        width: 96%;
        height: 100%;
        /* background-color: red; */
    }

    .conentsby h4 {
        color: #002676;
        padding-top: 0.4rem;
        text-decoration: underline;
    }

    .conentsby h4:hover {
        text-decoration: none;
    }

}


@media only screen and (max-width:750px) and (min-width:340px) {

    .innersub {
        width: 50%;
    }

    .bluecontainer {
        top: 13rem;
        left: 1rem;
        position: absolute;
        width: auto;
        height: 4rem;
        background-color: #431170;
        border: 1px solid #431170;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.2rem;
    }

    .bluecontainer h1 {
        color: #ffff;
        text-align: center;
        font-weight: 900;
        font-size: 2rem;
    }

}


@media only screen and (min-width:480px) and (max-width:1000px) {
    .innersub {
        width: 50%;
    }

    .paracontainer {
        padding-top: 1rem;
        margin: 1.5rem;
        margin-right: 2rem;
    }


    .content_para {
        color: #000;
        font-size: 1.7rem;
        line-height: 1.3125;
    }


}



@media only screen and (min-width:900px) and (max-width:1100px) {
    .desktop_down_content {
        display: none;
    }

    .mobile_versionsss {
        width: 100%;
        overflow: hidden;
        display: flex !important;
        justify-content: space-between;
    }

    .top_contentss {
        width: 50%;
    }

    .down_contentss {
        padding-top: 1rem;
        width: 50%;
    }

    .hexagon1 {
        position: relative;
        width: 109px;
        height: 55px;
        background-color: #002676;
        margin: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: inline-block;
        border-left: solid 5px #002676;
        border-right: solid 5px #002676;
    }

    .hexagon1:before {
        content: "";
        position: absolute;
        top: -27px;
        left: 0;
        width: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 27.5px solid #002676;
    }

    .hexagon1:after {
        content: "";
        position: absolute;
        bottom: -26px;
        left: 0;
        width: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-top: 27.5px solid #002676;
    }

    .hexagon1 h3 {
        color: #ffff;
        text-align: center;

    }

    .circle1 {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #002676;
        border-radius: 50%;
    }

    .circle1 h3 {
        color: #ffff;
        font-size: 1.5rem;
    }

    .mobilehappens {
        /* width: 90%; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
    }

    .subcontainerrssmobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .innersub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }


    .subcontainerrssmobile {
        background-color: white;
    }

    .totaldowncontemt {
        display: flex;
        /* background-color: red; */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 28rem;
    }

    .subcontainerrssmobile p {
        width: 90%;
        color: #000;
        text-align: center;
    }

    .mobilehappens h5 {
        text-align: center;

        color: #002676;
        text-decoration: none;
    }

    .mobilehappens h5:hover {
        text-decoration: underline;
    }

    .mobile_versionsss {
        width: 100%;
        overflow: hidden;
        display: block;
    }

    .conaatinersubmon {
        width: 100%;
        margin-top: 1rem;
        height: 22rem;
        /* background-color: blue; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imagofmobile {
        width: 100%;
        height: 12rem;
    }

    .imagofmobile img {
        width: 100%;
        height: 100%;
    }

    .conatiner_mobile {
        width: 96%;
        height: 100%;
        /* background-color: red; */
    }

    .conentsby h4 {
        color: #002676;
        padding-top: 0.4rem;
        text-decoration: underline;
    }

    .conentsby h4:hover {
        text-decoration: none;
    }


}