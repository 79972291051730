@import url("https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=Barrio&family=Chewy&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Atma:wght@300;400;500;600;700&family=Barrio&family=Chewy&family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
.bg1 {
  background-color: #f2f6f7;
}

.logo-img {
  width: 58%;
}
.logoimage {
  max-width: 25% !important ;
}
.margin_bg {
  margin-top: 5vh;
}
.backGCol {
  background-color: transparent !important;
  box-shadow: none;
}

.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.ant-modal-content {
  padding: 5px !important;
}

/* Nishithaaaaa .................start.......................🤞 */

/* .containerofchange {
  width: 50% !important;
  height: 100% !important;
  animation: name-of-animation 2s;
  background: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  --blur: 1.75rem;
  --box-blur: calc(0.5 * var(--blur));
  --glow: var(--color);
  --size: 12rem;
  align-items: center;
  border-radius: 12px;
  border: 4px solid currentColor;
  box-shadow:
    inset 0 0 0 2px rgba(0, 0, 0, 0.15),
    0 0 0 2px rgba(0, 0, 0, 0.15),
    inset 0 0 var(--box-blur) var(--glow),
    0 0 var(--box-blur) var(--glow);
  color: var(--color, white);

  font-family: system-ui, sans-serif;
  height: var(--size);
  padding: 1rem;
  width: var(--size);
} */

.containerofchange {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(0, 91, 255, 1) 9%,
    rgba(9, 9, 121, 1) 50%,
    rgba(0, 91, 255, 1) 97%
  );
  backdrop-filter: blur(9px);
  color: #fff;
  border-radius: 12px;
  padding: 30px 40px;
}

.heavy-font_two {
  font-size: 1rem;
  font-weight: 600;
  color: #006466;
}

.prog_disc {
  color: #191966 !important;
}

.heavy-font {
  color: #191966;
  color: #006466 !important;
}

.above_clas {
  width: 50%;
  height: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-start_two {
  color: #090909;
  border-radius: 0.5em;
  background: #e8e8e8;
  cursor: pointer;
  display: flex;
  width: 97%;
  height: 13vh;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Lato", sans-serif;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
}

.program_det_card {
  width: 100%;
  display: flex;
  flex-wrap: wrap !important;
  padding: 1vh;
  gap: 1vh;
}

.card_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#changethesize {
  color: red !important;
  font-size: 9px !important;
}

.btttttttnnn {
  font-family: "Dosis", sans-serif;
}

.btttttttnnn {
  border-radius: 50px;
  background-image: linear-gradient(135deg, #feb692 0%, #ea5455 100%);
  box-shadow: 0 20px 30px -6px rgba(238, 103, 97, 0.5);
  outline: none;
  cursor: pointer;
  font-weight: bold;
  border: none;
  color: white;
}

.btnnnnnnbtn {
  cursor: pointer;
  position: relative;
  text-align: center;
  border: none;
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  overflow: hidden;
  box-shadow: 0 10px 50px #006eff;
  border-radius: 10px;
}
.flex-start {
  width: 48%;
  height: 13vh;
  color: #090909;
  border-radius: 0.5em;
  background: #e8e8e8;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Lato", sans-serif;
  /* background: transparent; */
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  /* box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff; */
}

.flex-start:active {
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

.flex-start:before {
  height: 0%;
  width: 2px;
}

.tomake_circle {
  height: 50px;
  width: 50px;
  border-radius: 60%;
  border: 1px solid #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconmmmmmm {
  font-size: 20px;
}

@keyframes name-of-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Nishithaaaaa .................end.......................🤞 */

.extraback {
  background-image: url(../public/assets/img/bg/bg.jpg);
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}

.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}

.pricing_container {
  position: absolute;
  width: 40%;
  height: 30vh;
  /* background: red; */
  bottom: 1;
}

.snip1255 {
  font-family: "Raleway", Arial, sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  max-width: 1000px;
  /* margin: 33px 0px 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.snip1255 .plan {
  margin: 0;
  width: 30%;
  height: 180px;
  position: relative;
  float: left;
  background-color: #fff;
  box-shadow: 0 0 3px #bfbfbf;
  color: #000000b0;
  border-radius: 6px;
  overflow: hidden;
  margin: 8px;
  border-bottom: 20px solid #b386bf7d;
  cursor: pointer;
}

.snip1255 .plan1 {
  margin: 0;
  width: 30%;
  height: 180px;
  position: relative;
  float: left;
  background-color: #fff;
  box-shadow: 0 0 3px #bfbfbf;
  color: #000000b0;
  border-radius: 6px;
  overflow: hidden;
  margin: 8px;
  border-bottom: 20px solid #4fc8eb91;
  cursor: pointer;
}

.snip1255 .plan2 {
  margin: 0;
  width: 30%;
  height: 180px;
  position: relative;
  float: left;
  background-color: #fff;
  color: #000000b0;
  border-radius: 3px;
  overflow: hidden;
  margin: 8px;
  cursor: pointer;
  border: 1px solid #061d6a;
  transition: all 0.3s ease;
}
.snip1255 .plan2:hover {
  border-right: 3px solid #061d6a;
  border-bottom: 3px solid #061d6a;
}

.snip1255 .hover {
  transform: scale(1.08);
  background-color: #fff;
  color: #000 !important;
  box-shadow: 0 2px 10px #b386bf;
  z-index: 1;
  font-weight: 700;
  border: 1px solid #b386bf;
  border-bottom: 20px solid #b386bf;
}

.snip1255 .hover1 {
  transform: scale(1.08);
  background-color: #fff;
  color: #000 !important;
  box-shadow: 0 2px 10px #4fc8eb;
  z-index: 1;
  font-weight: 700;
  border: 1px solid #4fc8eb;
  border-bottom: 20px solid #4fc8eb;
}

.snip1255 .hover2 {
  transform: scale(1.08);
  background-color: #fff;
  color: #000 !important;
  box-shadow: 0 2px 10px #37a5a3;
  z-index: 1;
  font-weight: 700;
  border: 1px solid #37a5a3;
  border-bottom: 20px solid #37a5a3;
}

.snip1255 .hover .plan-header {
  background-color: #f14d5d;
  height: 5px;
  position: absolute;
  bottom: 8px;
  left: 0;
}

.snip1255 .hover .h4span {
  /* background: #f14d5d !important; */
  color: #b386bf;
  padding: 4px;
  font-size: 20px;
}

.snip1255 .hover1 .h4span1 {
  /* background: #f14d5d !important; */
  color: #4fc8eb;
  padding: 4px;
  font-size: 20px;
}

.snip1255 .hover2 .h4span2 {
  /* background: #f14d5d !important; */
  color: #37a5a3;
  padding: 4px;
  font-size: 20px;
}

.snip1255 .h4span {
  /* background: #f7a1a9; */
  color: #b386bf7d;
  padding: 2px;
  font-size: 1.1rem;
}

.snip1255 .h4span1 {
  /* background: #f7a1a9; */
  color: #000;
  padding: 2px;
  font-size: 1.1rem;
}

.snip1255 .h4span2 {
  /* background: #f7a1a9; */
  color: #37a5a399;
  padding: 2px;
  font-size: 1.1rem;
}

.snip1255 .plan:hover h4 {
  color: #000;
}

.snip1255 .hover h4 {
  color: #000;
}

.snip1255 .plan:hover i,
.snip1255 .plan.hover i {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.snip1255 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.snip1255 header {
  position: relative;
  background-color: #fff;
  /* height: 4vh; */
}

.snip1255 header i {
  font-size: 56px;
  margin: 2px 0;
  color: #c0392b;
  display: inline-block;
}

.snip1255 .plan-title {
  top: 0;
  font-weight: 600;
  /* margin: 8px 0; */
  text-transform: capitalize;
  letter-spacing: 1px;
  /* background-color: #f5f5f5; */
  color: grey;
}

.snip1255 .plan-cost {
  background-color: #fff;
}

.snip1255 .plan-price {
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 700;
  font-size: 1em;
}

.snip1255 .hover .plan-price {
  font-family: "Montserrat", Arial, sans-serif;
  font-weight: 700;
  font-size: 1em;
  color: #000;
}

.snip1255 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.8em;
}

.snip1255 .hover .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.8em;
  color: #333333;
  font-weight: 700;
}

.snip1255 .plan-features li {
  /* padding: 2px 5%; */
  font-weight: 600;
  text-align: center;
}

.snip1255 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}

.mobileversion {
  display: none;
}

.sm-hr hr,
.sm-hr1 {
  border: 0;
  height: 2px;
  background-color: #f14d5d;
  margin: 6px 0px;
  font-weight: bold;
}

.sm-hr {
  width: 95px;
}

.sm-hr1 {
  width: 167px;
}

.active_button {
  background-color: #f14d5d;
}

.active_button a {
  color: #fff;
}

.disabled_button {
  background-color: #eee;
}

.disabled_button a {
  color: #bbb;
}

.image_align {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paragraphs {
  font-weight: 500;
  color: rgb(36, 36, 36) !important;
  /* color: #000; */
  font-size: 1.1rem;
  text-align: justify;
}

.h2_price-content-list ul.list_style {
  list-style: none !important;
}

.check {
  color: green;
  margin-right: 1vh;
  font-size: 1rem;
}

.non_check {
  color: red;
  margin-right: 1vh;
  font-size: 1.13rem;
}

.set_remember_inputts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backgroundimage {
  background-image: url("../public/assets/img/category/1/uni2.jpg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.ant_drop_down {
  /* width: 81.1vh !important; */
}

.currsor_po {
  cursor: pointer;
}

.tooltip_style {
  width: 100vh !important;
  text-align: justify;
}

.sing_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sing_image img {
  height: 10vh;
}

.drop_down_conta {
  display: flex;
  justify-content: space-between;
}

.ant_drop_down_small {
  width: 26vh !important;
  border-radius: 0 !important;
}

.account-title2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.registation_container {
  width: 100%;
  /* height: 84.6vh; */
  background: #f4f7ff;
  display: flex;
}

.registation_container .registation_menu_bar {
  width: 20%;
  height: 100%;
  overflow: hidden;
  /* max-height: 0; */
}

.toglebar {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3vh;
  background: #fff;
}

.icon_tobler {
  font-size: 1.6rem;
}

.registation_container .registation_menu_bar_close {
  width: 5%;
  height: 100%;
  /* overflow: hidden; */
  /* max-height: 0; */
}

.registation_container .registation_menu_bar_close .toglebar {
  padding-right: 0.9vh;
}

.registation_container .registation_component_space {
  width: 80%;
  /* height: 100%; */
}

.registation_container .registation_component_space_close {
  width: 95%;
  /* height: 100%; */
}

.wel_p {
  font-size: 1rem;
  margin-top: 1vh;
  color: rgb(47, 58, 128);
  font-weight: 600;
}

.background_wel {
  background: #e2e2fb;
}

.main_container {
  width: 100%;
  /* background: #c0392b; */
  margin-top: 2vh;
  display: flex;
  justify-content: center;
}

.main_container .container_box {
  width: 98%;
  padding: 10px;
  /* background: #faecea; */
}

.Details_container_box {
  width: 98%;
  padding: 10px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.registation_container .registation_component_space_close {
  width: 95%;
  /* height: 100%; */
}

.stud_reg_head_cont {
  width: 100%;
  /* height: 20vh; */
  padding: 3vh;
  background: #e2e2fb;
  display: flex;
  flex-direction: column;
}

.stud_reg_head_title {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
}

.stud_reg_head_description {
  font-size: 1rem;
  font-weight: 500;
  color: #62626a;
  font-style: italic;
  margin-top: 1vh;
}

.inputs_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2vh;
  padding-top: 3vh;
  display: flex;
  /* justify-content: center; */
  padding-bottom: 3vh;
}

.inputs_container .dn_input_box {
  width: 49%;
  display: flex;
  justify-content: center;
}

.inputs_container .radio_div_container {
  width: 98%;
  display: flex;
  flex-direction: column;
}

.dn_input_box .input_style {
  width: 98%;
}

.radio_title {
  font-size: 0.8rem;
  font-weight: 600;
  color: #333;
}

.radio_description {
  font-size: 0.7rem;
  font-weight: 500;
  color: #71717a;
  font-style: italic;
  margin: 0.6vh 0;
}

.dasbord_header_container {
  width: 100%;
  height: 13vh;
  display: flex;
  flex-direction: row;
  background: #fff;
}

.dasbord_header_container .dashbord-logo {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashbord-logo img {
  /* width: 90%; */
  height: 70%;
}

.dasbord_header_container .dashbord-menu_div {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3vh;
}

.button_container {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2vh;
}
.button_container_distance {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vh;
  /* #1677ff */
}

.back_btn_btn {
  background-color: #fff !important;
  border-color: #1677ff !important;
  color: #1677ff !important;
}

.drop_small_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.drop_small_container .dropd_small_input_style {
  width: 32%;
}

.drop_small_container .dropd_small_input_style_two {
  width: 49%;
}

.reg_welcome_containere {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.reg_welcome_containere .reg_welcome_box {
  width: 98%;
  height: 100%;
  /* background: yellow; */
  display: flex;
  flex-direction: row;
  /* border: 1px solid #000; */
  border-radius: 5px;
  background: #e2e2fb;
}

.reg_welcome_box .reg_welcome_box_image {
  width: 33%;
  height: 100%;
  /* background: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg_welcome_box .reg_welcome_box_dis {
  width: 67%;
  height: 100%;
  /* background: red; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10vh;
}

.h6_cta-button1 {
  width: 100%;
  height: 10vh;
}

.h6_cta-btn1 {
  color: #b1040e;
  font-weight: 600;
  width: 30vh;
  height: 7vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.option_major {
  font-size: 0.9rem;
  font-weight: 500;
  color: #71717a;
  font-style: italic;
  padding-left: 5vh;
}

.row_direction_coloum {
  display: flex;
  flex-direction: column;
}

.row_direction_coloum .radio_title {
  padding-bottom: 1vh;
}

.background_style {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.max_height_cont {
  /* width: 100%;
  height: 85vh;
  background: #b1040e; */
}

.heavy-font {
  font-size: 1rem;
  font-weight: 600;
  /* color: #421c64; */
  /* color: linear-gradient(-45deg, #743ad5, #d53a9d); */
  /* color: #5b42f3; */
  color: #000;
}

.heavy-font_colour {
  color: #1e1e1e;
}

.prog_disc {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000 !important;
}

/* //match university */

.unidispconta {
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.unidisBox1 {
  width: 30%;
  height: 12vh;
  border-radius: 5px;
  border: 1px solid #997fe6;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
}

.unidisBox2 {
  width: 30%;
  height: 12vh;
  border-radius: 5px;
  border: 1px solid #ff8e37;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
}

.unidisBox3 {
  width: 30%;
  height: 12vh;
  border-radius: 5px;
  border: 1px solid #57d3db;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
}

.imagebox {
  width: 10vh;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textbox {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin: 0 3vh;
}

.textbox h2 {
  font-weight: 700;
  font-size: 3.5vh;
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-items: center;
  align-items: center;
}

.textbox h4 {
  font-weight: 500;
  font-size: 2.5vh;
  margin: 0 !important;
  padding: 0 !important;
}

.uniheader {
  /* display: flex; */
  align-items: center;
  /* margin: 1vh 5vh;*/
}

.uniheader h1 {
  font-weight: 600;
  font-size: 4.5vh;
  color: #000000;
  margin: 0 2vh;
}

.sdropdown {
  width: 100%;
  position: relative;
}

.dropheader {
  width: 100%;
  height: 7vh;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2vh;
}

.dropheader h5 {
  color: #000;
  font-size: 2.7vh;
}

.sdropdown .droplistBox {
  width: 100%;
  height: 50vh;
  background-color: #fbeaf3;
  position: absolute;
  top: 7vh;
  left: 0;
  margin-bottom: 3vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 1vh;
  z-index: 9999;
}

.sdropdown .droplistBoxState {
  width: 100%;
  height: 40vh;
  background-color: #fbeaf3;
  position: absolute;
  bottom: 4vh;
  left: 0;
  margin-bottom: 3vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 1vh;
  z-index: 9999;
}

.sdropdown .droplistBoxtype {
  width: 100%;
  height: 13vh;
  background-color: #fbeaf3;
  position: absolute;
  top: 7vh;
  left: 0;
  margin-bottom: 3vh;
  padding: 0 1vh;
  z-index: 9999;
}

.checkmark {
  height: 18px;
  width: 18px;
  background-color: #d93c92 !important;
  margin-right: 2vh;
  border: #d93c92;
}

.custom-menu-item {
  color: red;
  background-color: yellow;
}

.custom-submenu-title {
  color: green;
  font-weight: bold;
}

.line_elips {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ant_custo .ant-select-selector {
  border: 1px solid #333333 !important;
}

.ant_custo .ant-select-arrow {
  color: #333333 !important;
}

.advise_conta {
  width: 100%;
  height: 30vh;
  background: #57d3db;
  border-radius: 1vh;
  padding: 2vh;
}

.advise_div {
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 1.5vh;
}

.adv_tit_conta {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adv_tit_conta span {
  font-weight: 700;
  font-size: 1.4rem;
  font-style: italic;
  text-decoration-line: underline;
  color: #fff;
}

.adv_dis_conta {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sing_dis_div {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spa_dis_1 {
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.spa_dis_2 {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}

.spa_dis_3 {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
}

.program_container {
  width: 100%;
  height: 80vh;
  /* background-color: #37a5a3; */
  overflow-y: scroll;
}

/* width */
.program_container::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.program_container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
}

/* Handle */
.program_container::-webkit-scrollbar-thumb {
  background: rgb(4, 135, 243);
  border-radius: 1px;
}

/* Handle on hover */
.program_container::-webkit-scrollbar-thumb:hover {
  background: rgb(4, 135, 243);
}

.program_box {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
}

.program_box p {
  width: 80%;
  height: 5vh;
  color: #333;
  font-size: 0.7rem;
  line-height: 15px;
}

.program_box span {
  width: 20%;
  height: 5vh;
  color: #333;
  font-size: 0.7rem;
  text-align: center;
}

.draft_header {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.draft_header span {
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
  font-style: italic;
  text-decoration-line: underline;
}

.main_letter_box {
  width: 100%;
  padding: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.main_letter_box span {
  color: #333;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: justify;
}

.margin_tops {
  margin-top: 2vh;
}

.tex_capita {
  text-transform: capitalize;
}

.text_lowercase {
  text-transform: lowercase;
}

.sub_containere {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.sub_containere .reg_welcome_box {
  width: 100%;
  height: 100%;
  /* background: yellow; */
  display: flex;
  flex-direction: row;
  /* border: 1px solid #000; */
  border-radius: 5px;
  background: #e2e2fb;
}

.reg_welcome_containere .reg_welcome_box {
  width: 98%;
  height: 100%;
  /* background: yellow; */
  display: flex;
  flex-direction: row;
  /* border: 1px solid #000; */
  border-radius: 5px;
  background: #e2e2fb;
}

.reg_welcome_box .reg_welcome_box_image {
  width: 33%;
  height: 100%;
  /* background: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg_welcome_box .reg_welcome_box_image_doal {
  width: 33%;
  height: 100%;
  /* background: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.reg_welcome_box_image_doal img {
  width: 35%;
  height: 70%;
}

.reg_welcome_box .reg_welcome_box_dis {
  width: 67%;
  height: 100%;
  /* background: red; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10vh;
}

.two-line-text {
  white-space: pre-line;
}

.ant-menu-inline .ant-menu-item {
  line-height: 20px !important;
}

.howwork_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.howwork_cont_text_box {
  width: 80%;
  height: 35vh;
  background: red;
}
.howwork_cont_text_box h4 {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
}
.how_pr_box {
  width: 100%;
  height: 27vh;
  background: pink;
  display: flex;
  flex-direction: row;
}
.how_pr_box_left {
  width: 70%;
  height: 100%;
  background: pink;
  display: flex;
  flex-direction: row;
}
.how_pr_box_right {
  width: 30%;
  height: 100%;
  background: pink;
  display: flex;
  flex-direction: row;
}
.education_model_text {
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #000;
}

.remove_more_btn {
  background-color: #4d97ff !important;
  border-color: #4d97ff !important;
}
.add_more_btn {
  background-color: #4d97ff !important;
  border-color: #4d97ff !important;
}

@media only screen and (max-width: 767px) {
  .desktopversion {
    display: none;
  }

  .registation_menu_bar {
    display: none;
  }

  .registation_menu_bar_close {
    display: none;
  }

  .registation_container .registation_component_space {
    width: 100%;
  }

  .registation_container .registation_component_space_close {
    width: 100%;
  }

  .tooltip_style {
    width: 55vh !important;
  }

  .ant_drop_down_small {
    width: 14vh !important;
  }

  .account-main h3 {
    font-size: 1.4rem;
  }

  .fillinfopadding {
    padding-top: 7vh !important;
  }

  .mobileversion {
    display: block;
    padding: 12px;
  }

  .ant_drop_down {
    /* width: 58vh !important; */
  }

  .snip1255 .plan {
    width: 94%;
    height: 205px;
  }

  .snip1255 .plan1 {
    width: 94%;
    height: 205px;
  }

  .snip1255 .plan2 {
    width: 94%;
    height: 205px;
  }

  .snip1255 .hover {
    width: 90%;
    margin-left: 20px;
  }

  .snip1255 .plan-title,
  .snip1255 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .snip1255 .plan-select a,
  .snip1255 .featured .plan-select a {
    border: 1.5px solid #f14d5d;
    border-radius: 20px;
    box-shadow: 0.4px 0.5px #f14d5d;
    margin-bottom: 10px;
  }

  .snip1255 .featured {
    margin-top: 0;
  }

  .dasbord_header_container {
    height: 8vh;
  }

  .inputs_container .dn_input_box {
    width: 98%;
    display: flex;
    justify-content: center;
  }

  .reg_welcome_containere {
    height: 85vh;
  }

  .reg_welcome_containere .reg_welcome_box {
    width: 98%;
    flex-direction: column;
  }

  .reg_welcome_box .reg_welcome_box_image {
    width: 100%;
    height: 100%;
  }

  .reg_welcome_box .reg_welcome_box_dis {
    width: 100%;
    height: 100%;
  }

  .h6_cta-button1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stud_reg_head_cont {
    width: 100%;
  }
}

.category-img img {
  width: auto;
}

/*  ------------------------------------------*/
.viewdatainput {
  width: 31%;
}

.personal_details_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2vh;
  padding-top: 3vh;
  display: flex;
  /* justify-content: center; */
  padding-bottom: 3vh;
  border: 0.5px solid gainsboro;
  padding-left: 16px;
  
}
